@font-face {
  font-family: "DMSans-medium";
  src: url("./DMSans-Medium.ttf");
}

@font-face {
  font-family: "DMSans-regular";
  src: url("./DMSans-Regular.ttf");
}

@font-face {
  font-family: "DMSans-bold";
  src: url("./DMSans-Bold.ttf");
}