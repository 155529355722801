/* You can add global styles to this file, and also import other style files */
@import "./assets/fonts/style-font.scss";

$colorBg: #363030;
$colorForm: #222;

html,
body {
  padding: 0;
  margin: 0;
  background-color: $colorBg;
  width: 100%;
  height: 100%;
  font-family: "DMSans-regular";
}

p-calendar {
  .p-calendar {
    width: 100%;

    input {
      width: 100%;
    }
  }
}

a {
  text-decoration: none !important;
  font-family: "DMSans-Bold";
  color: #fff;

  &:hover {
    cursor: pointer;
  }
}

@media only screen and (max-height: 800px) {
  .form-custom {
    align-items: flex-start;
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.form-custom {
  display: flex;
  justify-content: center;

  form {
    width: 446px;
    max-width: 100%;
    padding: 2rem 3rem 1.5rem;
    border-radius: 23px;
    background: $colorForm;
    position: relative;

    .logo-img {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;
      img {
        width: 220px;
      }
    }

    .form-content {
      min-height: 350px;
    }

    .icon {
      margin-bottom: 1rem;

      i {
        font-size: 1.5rem;
        color: #fff;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .icon-right {
      position: absolute;
      right: 50px;
      top: 85px;

      i {
        font-size: 1.5rem;
        color: #fff;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .title {
      font-family: "DMSans-Bold";
      font-weight: bold;
      font-size: 30px;
      color: #fff;
    }

    .sub-title {
      margin: 0.5rem 0 1.5rem;
      font-size: 16px;
      color: #fff;
    }

    .form-group {
      margin-bottom: 1rem;
      width: 100%;

      .form-input {
        width: 100%;
        position: relative;
        display: block;

        .input-text {
          width: 100%;
          font-size: 14px;
          border: none;
          background-color: #393b4d;
          padding: 13px;
          color: #fff;
          border-radius: 5px;

          &::placeholder {
            font-weight: normal;
            text-align: left;
            color: #fff;
            opacity: 0.5;
          }

          &:hover,
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }

        .select {
          display: flex;
          justify-content: space-between;

          .select-33 {
            width: 30%;
          }
        }

        .input-password {
          width: 100%;
          font-size: 14px;
          border: none;
          background-color: #393b4d;
          padding: 13px 52px 13px 13px;
          color: #fff;
          border-radius: 5px;

          &::placeholder {
            font-weight: normal;
            text-align: left;
            color: #fff;
            opacity: 0.5;
          }

          &:hover,
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }

        img {
          position: absolute;
          top: calc(50% + 3px);
          right: 16px;
          width: 20px;

          &:hover {
            cursor: pointer;
          }
        }

        label {
          font-family: "DMSans-Medium";
          font-size: 14px;
          color: #fff;
          display: block;
          margin-bottom: 4px;
        }
      }

      .form-checkbox {
        .container {
          display: block;
          position: relative;
          padding-left: 25px;
          margin-bottom: 20px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          color: #fff;

          &:hover {
            cursor: pointer;
          }

          div {
            font-weight: normal;
            font-size: 16px;
            text-align: left;
            color: #fff;
            opacity: 1;
            transition: opacity 0.5s;

            a {
              font-family: "DMSans-Bold";
            }
          }

          input {
            position: absolute;
            opacity: 0;
            height: 0;
            width: 0;

            &:hover {
              cursor: pointer;
            }
          }

          .checkmark {
            position: absolute;
            top: 2.5px;
            left: 2.5px;
            height: 15px;
            width: 15px;
            background: white;
            border: 1px solid gray;
            border-radius: 4px;
            opacity: 1;

            // &::after {
            //   position: absolute;
            //   content: "";
            //   opacity: 0;
            //   left: 2px;
            //   top: 2px;
            //   width: 9px;
            //   transition: opacity 0.5s;
            //   height: 9px;
            //   background: #448aff;
            // }
          }

          input:checked ~ .checkmark {
            opacity: 1;

            &::after {
              position: absolute;
              content: "✓";
              font-size: 12px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;
              color: #448aff;
              font-weight: bold;
            }
          }

          input:checked ~ div {
            opacity: 1;
          }
        }
      }

      .form-button {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 30px;

        button {
          font-family: "DMSans-Medium";
          font-size: 17px;
          text-align: center;
          color: #fff;
          width: 100%;
          height: 56px;
          border-radius: 10px;
          background: #448aff;
          border: none;
          transition: opacity 0.5s;

          &:hover {
            cursor: pointer;
          }

          &:disabled {
            opacity: 0.5;
          }

          &:hover,
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }

      .text-between-line {
        width: 100%;
        text-align: center;
        border-bottom: 2px solid #707070;
        line-height: 0.1em;
        margin: 30px 0;

        span {
          font-family: "DMSans-Medium";
          font-weight: 500;
          font-size: 14px;
          text-align: center;
          color: #fff;
          padding: 0 50px;
          background: #222;
        }
      }

      .link-shadow {
        text-align: center;
        margin-bottom: 10px;

        a {
          text-decoration: none !important;
          font-weight: normal;
          font-size: 14px;
          color: #808080;
          transition: color 0.5s;

          &:hover {
            color: #b2b2b2;
          }
        }
      }

      .link {
        font-weight: normal;
        font-size: 16px;
        text-align: center;
        color: #fff;

        a {
          color: #448aff;
          text-decoration: none;
        }
      }
    }
  }
}

.h-custom {
  padding-top: 15px !important;
  height: 127px !important;
}

@media only screen and (max-width: 720px) {
  html,
  body {
    background: $colorForm;
  }

  .form-custom {
    position: relative;
    width: 100%;

    form {
      padding: 0px;
      width: 100%;
      background: transparent;

      .icon {
        position: relative;
        top: 0;
        left: 0;
        margin-bottom: 15px;
      }

      .icon-right {
        position: relative;
        top: 0;
        right: 0;
        display: flex;
        justify-content: flex-end;
        margin-right: 15px;
      }

      .form-group {
        .text-between-line {
          span {
            background: $colorForm;
          }
        }

        .link {
          font-size: 14px;
        }
      }
    }
  }

  .h-custom {
    height: 145px !important;
  }
}

.p-invalid {
  color: rgb(206, 55, 55) !important;
  position: relative;
  margin-top: 5px;
  width: 100%;
  text-align: left;
  display: block;
  font-size: 12px;

  a {
    color: rgb(206, 55, 55) !important;
  }
}

.disabled {
  position: relative;
  z-index: 0;
  overflow: hidden;
  pointer-events: none;

  &::after {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba($color: #000000, $alpha: 0.2);
    cursor: not-allowed;
    content: "";
    z-index: 1;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}

.p-dropdown {
  background: transparent !important;
  border: 2px solid #b8b8b8 !important;
  height: 45px;
  outline: none !important;
  box-shadow: none !important;
  width: 100%;
  border-radius: 15px !important;

  .p-dropdown-trigger {
    width: unset !important;
    padding-right: 10px;
  }

  .p-dropdown-label {
    display: flex;
    color: rgba($color: #fff, $alpha: 0.5);
    font-size: 16px;
    font-family: "DMSans-regular";
    align-items: center;
  }
}

.p-dropdown-panel {
  background: rgba($color: #000000, $alpha: 0.7) !important;

  &:hover ~ .p-dropdown,
  &:focus ~ .p-dropdown {
    outline: none !important;
    box-shadow: none !important;
  }

  .p-dropdown-items {
    .p-dropdown-item {
      color: #fff !important;

      &:hover {
        color: #000 !important;
      }
    }

    .p-highlight {
      color: #000 !important;
    }
  }
}

.blue {
  color: #448aff;
  font-weight: bold;
}

#scrollbar-form::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 5px;
  background-color: transparent;
}

#scrollbar-form::-webkit-scrollbar {
  width: 7px;
  background-color: transparent;
}

#scrollbar-form::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: rgba($color: #fff, $alpha: 0.3);
}

ngx-intl-tel-input {
  .iti--allow-dropdown {
    width: 100%;

    .iti__selected-flag {
      .selected-dial-code {
        font-family: "DMSans-regular" !important;
        color: #fff;
        font-size: 16px !important;
      }
    }

    .custom {
      font-size: 16px !important;
      padding: 10px 15px 10px 0;
      color: #fff;
      width: 100%;
      border-radius: 5px;
      max-width: 100%;
      position: relative !important;
      background: #393b4d !important;
      font-family: "DMSans-regular" !important;
      border: none;
      transition: all 0.5s !important;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &::placeholder {
        color: rgba($color: #fff, $alpha: 0.5) !important;
      }
    }
  }
}

.p-dialog {
  background: $colorForm;
  max-width: 100%;

  .p-dialog-header {
    background: transparent !important;

    .p-dialog-title {
      width: 100%;
      font-size: 22px !important;
      color: #fff;
      text-align: center;
      font-family: "DMSans-regular";
    }
  }

  .p-dialog-content {
    background: transparent !important;
  }
}

@media only screen and (max-width: 720px) {
  .form-custom {
    form {
      .scrollbar-form {
        height: auto !important;
        max-height: none !important;
      }

      .form-content {
        min-height: unset;
      }
    }
  }
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  .bar {
    background-color: transparent;
    min-width: 100%;
    min-height: 5px;
    position: relative;
    overflow: hidden;

    &::after {
      content: "";
      min-height: 5px;
      position: absolute;
      background: #fff;
      transform: translateX(-100%);
      animation: animate 2s infinite;
    }

    &::before {
      content: "";
      min-height: 5px;
      position: absolute;
      background: #fff;
      transform: translateX(-100%);
      animation: animate 2s infinite;
      animation-delay: 1s;
    }
  }
}

@keyframes animate {
  0% {
    transform: translateX(-150%);
    min-width: 100px;
  }

  100% {
    transform: translateX(300%);
    min-width: 400px;
  }
}

ngx-otp-input {
  input {
    font-size: 21px !important;
    border-width: 1px !important;
    border-color: #d5d7e3 !important;
    border-style: solid !important;
    color: #5a607f !important;
    width: 41px !important;
    height: 53px !important;

    &:focus {
      border-color: #707070 !important;
    }
  }
}
